import merge from 'lodash/merge';

import { canUseDOM } from './dom-utils';

/**
 * Calculate the parent domain for a site. E.g.: the parent of `listado.mercadolivre.com.br` is `mercadolivre.com.br`.
 * This method does not require to have a list of domains.
 * @param {string} domain Full Domain name
 * @returns Generic domain name for the site
 */
const getPrimaryDomain = (domain) =>
  domain.split('.').reduce((prev, curr) => {
    if (prev && prev !== '') {
      return `${prev}.${curr}`;
    }

    if (curr?.includes('mercadoli')) {
      return curr;
    }

    return '';
  }, '') || domain;

/**
 * Companion function of `markWithCookie`. If a cookie was created with `markWithCookie`,
 * this function will return `true`
 * @param {string} cookieName Name of the cookie that we are searching
 * @returns true if the cookie is present, false otherwise
 */
const isCookieMarked = (cookieName) => {
  if (canUseDOM && document && document.cookie) {
    const cookieInfo = document.cookie.split(';').find((s) => s.split('=')[0].trim() === cookieName);

    return (cookieInfo && (cookieInfo.indexOf('userhide') > 0 || cookieInfo.indexOf('hidden') > 0)) || false;
  }

  return false;
};

/**
 * This function creates a new cookie for the parent domain. It can then be retrieved via `isCookieMarked`.
 * @param {string} cookieName Name of the cookie that we will set
 * @param {long} secondsMarked How much seconds should this cookie be alive
 */
const markWithCookie = (cookieName, secondsMarked) => {
  if (canUseDOM && document && document.cookie) {
    // calculamos el domain más genérico para el site.
    const primaryDomain = getPrimaryDomain(document.domain);
    const age = secondsMarked ? `;max-age=${secondsMarked}` : '';

    // 1 día = 60 segs * 60 mins * 24 hs = 86400
    document.cookie = `${cookieName}=userhide${age};domain=${primaryDomain};path=/`;
  }
};

/**
 * This function gets a list of cookies that match a prefix.
 * @param {Object} cookies Name of the list of cookie that we will set
 * @param {string} prefix  Name of the cookie prefix that we will set
 * @returns list of cookies starting with the prefix
 */
const getListCookieByPrefix = (cookies, prefix) => {
  const listCookie = {};

  Object.keys(cookies).forEach((cookieName) => {
    if (cookieName.startsWith(prefix)) {
      listCookie[`${cookieName}`] = cookies[`${cookieName}`];
    }
  });

  return listCookie;
};
const getCookie = (name) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const match = RegExp(`(^|;\\s*)(${name})=([^;]*)`).exec(document.cookie);

  if (match) {
    try {
      return { [name]: JSON.parse(match[3]) };
    } catch (e) {
      return { [name]: match[3] };
    }
  }

  return null;
};

const setCookie = (name, value, attrs = {}) => {
  let cookieValue;

  if (typeof value === 'object') {
    const cookie = getCookie(name);

    cookieValue = JSON.stringify(cookie ? merge(cookie[name], value) : value);
  } else {
    cookieValue = value;
  }

  const keys = Object.keys(attrs);

  let cookieAttrs = '';

  if (keys.length > 0) {
    cookieAttrs = keys.reduce((acum, key) => `${acum};${key}=${attrs[key]}`, '');
  }

  document.cookie = `${name}=${cookieValue}${cookieAttrs}`;
};

export { getPrimaryDomain, isCookieMarked, markWithCookie, getListCookieByPrefix, setCookie, getCookie };

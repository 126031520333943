import { string } from 'prop-types';

import registerMetric from '../../api/services/stats.service';
import {
  ERROR_CATCHER,
  SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES,
  SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX,
  UNKNOWN_VALUE,
  VERTICAL_APP,
} from '../constants';
import checkPops from './check-pops';

const registerErrorMetrics = ({
  errorType,
  errorCatcher,
  key = null,
  errorMessage,
  errorStack,
  errorServiceName = UNKNOWN_VALUE,
}) => {
  const pageType = window?.PAGE_TYPE || UNKNOWN_VALUE;
  const componentName = window?.ERROR_COMPONENT_NAME || UNKNOWN_VALUE;

  const verticalApp =
    checkPops({
      key: window?.VERTICAL_APP__NAME,
      listOfKey: Object.keys(VERTICAL_APP),
      defaultReturn: VERTICAL_APP.CORE?.toLowerCase(),
    }) ?? UNKNOWN_VALUE;
  const keySelect =
    key ?? `${SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX}.${SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES.JS_ERROR}`;
  const checkErrorCatchExist = checkPops({
    key: errorCatcher,
    listOfKey: Object.keys(ERROR_CATCHER),
    defaultReturn: UNKNOWN_VALUE,
  });

  registerMetric({
    key: keySelect,
    tags: {
      error_type: errorType,
      vertical_app: verticalApp,
      error_component_name: componentName,
      error_page_type: pageType,
      error_catcher: checkErrorCatchExist,
      error_service_name: errorServiceName,
    },
    info: {
      error_component_name: componentName,
      error_message: errorMessage,
      error_stack: errorStack,
    },
    messageTitle: 'Search-ErrorHandler - JsError',
  });

  window.ERROR_BOUNDARY = undefined;
  window.ERROR_COMPONENT_NAME = undefined;
  window.VERTICAL_APP__NAME = undefined;
};

registerErrorMetrics.protoType = {
  key: string,
  errorType: string.isRequired,
  errorCatcher: string.isRequired,
  errorMessage: string.isRequired,
  errorStack: string,
  errorServiceName: string,
};

export { registerErrorMetrics };

import RestClient from '../../services/client/restclient';

const request = RestClient({
  timeout: 10000,
  baseURL: '/api/search',
});

const SERVICE_BASE_PATH = '/stats';

const registerMetric = (data) => {
  // TODO: ver si tenemos soporte para beacon, ya que realmente no nos importa el response
  request.post(SERVICE_BASE_PATH, {
    data,
  });
};

export default registerMetric;

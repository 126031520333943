import { Component } from 'react';
import { bool, element, func, string } from 'prop-types';

import { ERROR_CATCHER } from '../../constants';
import { registerErrorMetrics } from '../../utils/register-error-metrics';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error) {
    const { newrelic } = window || {};
    const { customError } = this.props;

    registerErrorMetrics({
      errorType: 'uncaught_reference_error',
      errorCatcher: ERROR_CATCHER.ERROR_BOUNDARY,
      errorStack: error.stack,
      errorMessage: error.message,
    });

    if (newrelic) {
      newrelic.noticeError(error, {
        stack: error.stack,
        message: error.message,
      });
    }

    if (customError) {
      customError(error?.message);
    }
  }

  render() {
    const { children, siteId, fallback = null, componentName, isCritical = false, verticalApp } = this.props;

    if (!this.state.hasError) {
      return children;
    }

    window.ERROR_COMPONENT_NAME = componentName;
    window.VERTICAL_APP__NAME = verticalApp;
    window.ERROR_BOUNDARY = ERROR_CATCHER.ERROR_BOUNDARY;

    if (isCritical && componentName !== 'MainPage') {
      throw new Error(`Critical error in component ${componentName}`);
    }

    return fallback?.({ siteId });
  }
}

ErrorBoundary.propTypes = {
  children: element.isRequired,
  componentName: string,
  customError: func,
  fallback: element,
  isCritical: bool,
  siteId: string,
  verticalApp: string,
};

export default ErrorBoundary;

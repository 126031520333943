import ErrorBoundary from '../../components-v2/error/error-boundary';
import countryConfig from '../../utils/andes-locale-country';
import { renderView } from '../../utils/render';

const View = (props) => renderView(ErrorBoundary, props);

export const getServerSideProps = (req) => {
  const { id: platformId, siteId } = req.platform;
  const { errorContext } = req;
  const country = countryConfig(req.platform.countryId);
  const { type: deviceType } = req.device;

  return {
    props: {
      siteId,
      analyticsTracks: {
        siteId,
        platform: platformId,
        section: 'search',
        page: '500',
      },
      errorContext,
      country,
      deviceType,
    },
  };
};

export default View;

import React from 'react';

import { AndesProvider } from '@andes/context';

const renderView = (Component, props) => {
  const { deviceType, country } = props;

  return (
    <AndesProvider locale={country?.andesLocale} device={{ type: deviceType }}>
      <Component {...props} />
    </AndesProvider>
  );
};

export { renderView };

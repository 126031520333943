const UNKNOWN_VALUE = 'UNKNOWN_VALUE';
const SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX = 'error_handler';
const SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES = {
  JS_ERROR: 'js_error',
  SCRIPT_CATCH: 'script_catch',
};

const TAG_APP_NEME = 'search';

const VERTICAL_APP = {
  CORE: 'core',
  CLIPS: 'clips',
  SUPER: 'super',
  ESHOP: 'eshops',
  MODA: 'moda',
  VIS: 'vis',
  COMPATS: 'compats',
  CBT: 'cbt',
  BRAND_ADS: 'brand_ads',
  PADS: 'pads',
};

const ERROR_CATCHER = {
  ERROR_BOUNDARY: 'error_boundary',
  MIDDLEWARE: 'middleware',
  TRY_CATCH: 'try_catch',
  JS_ERROR: 'js_error',
};

export {
  UNKNOWN_VALUE,
  SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX,
  SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES,
  TAG_APP_NEME,
  VERTICAL_APP,
  ERROR_CATCHER,
};

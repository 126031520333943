const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

/**
 * Returns true if the HTML5 history API is supported. Taken from Modernizr.
 *
 * https://github.com/Modernizr/Modernizr/blob/master/LICENSE
 * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/history.js
 * changed to avoid false negatives for Windows Phones: https://github.com/reactjs/react-router/issues/586
 */
const supportsHistory = () => {
  if (!canUseDOM) {
    return false;
  }

  const ua = window.navigator.userAgent;

  if (
    (ua.includes('Android 2.') || ua.includes('Android 4.0')) &&
    ua.includes('Mobile Safari') &&
    !ua.includes('Chrome') &&
    !ua.includes('Windows Phone')
  ) {
    return false;
  }

  return window.history && 'pushState' in window.history;
};

const getLocationForHash = (hash) =>
  canUseDOM ? `${window.location.origin}${window.location.pathname}${window.location.search}#${hash}` : null;

const supportsIntersectionObserver =
  canUseDOM &&
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype;

const isLocalStorageSupported = () => {
  const test = 'test';

  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);

    return true;
  } catch (e) {
    return false;
  }
};

export { canUseDOM, supportsIntersectionObserver, supportsHistory, getLocationForHash, isLocalStorageSupported };
